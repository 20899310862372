.root {
  @apply inline-flex cursor-pointer items-center space-x-2 rounded-full px-8 py-3
  text-base font-medium transition duration-300 ease-out;
}

/* Variants */
.primary {
  @apply border border-blue-200 bg-blue-200 text-white hover:border-blue-300 hover:bg-blue-300;
}
.secondary {
  @apply border border-current bg-transparent text-current hover:border-black hover:bg-black hover:text-white;
}
.tertiary {
  @apply p-0 text-blue-200;
  &:hover > span {
    @apply underline;
  }
  &.disabled {
    @apply bg-transparent;
  }
}

.disabled {
  @apply pointer-events-none cursor-default border-0 bg-black/5 text-gray-400;
}

/* Sizes */
.small {
  @apply text-sm;
  &:not(.tertiary) {
    @apply px-6 py-2;
  }
}
.largeOnDesktop {
  @apply px-6 py-2 text-sm md:px-8 md:py-3 md:text-base;
}

/* Loading state */
.loading {
  @apply pointer-events-none cursor-default;
  & > * {
    @apply opacity-0;
  }
  & .spinner {
    @apply absolute inline-block opacity-100;
  }
}
