.menuButton {
  @apply relative -mr-1.5 h-9 w-9 cursor-pointer;
  & span {
    @apply absolute top-1/2 right-1.5 left-1.5 -mt-px block rounded-md bg-white;
    height: 2px;
  }
  & span:first-child {
    transform: translateY(-6px);
  }
  & span:last-child {
    transform: translateY(6px);
  }
}
