.root {
  @apply relative -my-2 flex-shrink-0 p-2;
}

.label {
  @apply hidden;
}

.avatar {
  @apply h-8 w-8 rounded-full;
}

@screen lg {
  .root {
    @apply flex items-center space-x-2;
  }
  .label {
    @apply relative block truncate text-right font-medium text-gray-300;
    max-width: 170px;
  }
}
