.root {
  @apply sticky -top-px z-header w-full bg-black py-3 text-white md:py-3.5;
}

.logo {
  @apply w-auto;
  height: 22px;
}

.divider::after {
  @apply mx-1 block w-px bg-white opacity-30;
  content: '';
  height: 27px;
}

.grid {
  @apply flex flex-wrap items-center justify-between md:flex-nowrap md:space-x-3;
}
.gridLeft,
.gridRight {
  @apply flex flex-shrink-0 items-center;
  transition: max-width 150ms;
}
.gridLeft {
  @apply space-x-3 lg:space-x-5;
}
.gridRight {
  @apply space-x-1 md:order-2;
}

@screen md {
  .logo {
    @apply mr-2;
    height: 24px;
  }
  .divider::after {
    @apply mx-2;
    height: 33px;
  }
  .autocompleteShowing .gridLeft,
  .autocompleteShowing .gridRight {
    @apply overflow-hidden;
    transition: max-width 400ms;
  }
  .autocompleteShowing .gridLeft {
    @apply max-w-0;
  }
  .autocompleteShowing .gridRight {
    max-width: 80px;
  }
}
