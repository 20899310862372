.listItem {
  @apply relative mb-0.5 py-2.5 pl-8;
}

.listItem a {
  @apply cursor-pointer	no-underline;
}

.listItem.open > .plus::after {
  @apply hidden;
}

.listItem.open > .categoryTreeL2,
.listItem.open > .categoryTreeL3 {
  @apply block;
}

.plus {
  @apply absolute left-0 inline-block cursor-pointer;
  top: 11px;
  margin-right: 11px;
  height: 21px;
  width: 21px;
}

.plus::before,
.plus::after {
  @apply absolute bg-black;
  content: '';
}

.plus::before {
  @apply left-0	h-px w-full;
  top: 10px;
}

.plus::after {
  @apply top-0 h-full w-px;
  left: 10px;
}

.current {
  @apply text-red-100;
}

.list {
  @apply m-0 list-none p-0;
}

.categoryTree {
  @apply m-0 py-4 px-0;
}

.categoryTreeL2,
.categoryTreeL3 {
  @apply hidden pb-2.5 pt-1.5;
}
.categoryTreeL3 {
  @apply -ml-1.5;
}
