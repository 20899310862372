.root {
  @apply flex flex-col;
  margin-bottom: 20px;
}

.root:first-child {
  @apply pt-0;
}

.quantity {
  appearance: textfield;
  @apply mx-3 w-8 rounded border border-gray-200 text-center text-sm text-black;
}

.quantity::-webkit-outer-spin-button,
.quantity::-webkit-inner-spin-button {
  @apply m-0 appearance-none;
}

.productImage {
  @apply absolute h-full w-full;
}

.productName {
  @apply cursor-pointer pb-1;
}
