.lineItemsList {
  @apply w-full;
}

.checkoutButtonContainer {
  @apply flex w-2/3 min-w-0;
}

.totalPriceContainer {
  @apply w-1/3;
  padding: 0 7.5px 0 0;
  font-size: 1rem;
}

@screen lg {
  .totalPriceContainer {
    padding: 0 15px 0 0;
    font-size: 1.185rem;
  }
}
