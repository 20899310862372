.actions {
  @apply flex w-12 items-center justify-center
  p-1 text-black;
  user-select: none;
}

.actions:hover {
  z-index: 10;
}

.actions:focus {
  @apply outline-none;
}

.actions:disabled {
  @apply cursor-not-allowed;
}

.input {
  @apply pointer-events-auto h-full w-full select-none bg-transparent px-4 focus:outline-none;
}
