.wrapper {
  @apply space-y-3 text-left;
}

.item {
  @apply overflow-hidden rounded-full border border-solid bg-white hover:bg-gray-100;
}

.label {
  @apply flex cursor-pointer flex-row-reverse items-center justify-between py-4 pl-5.5 pr-6;
}
.label input {
  @apply m-1;
}

.flagWrapper {
  @apply mr-4;
}

.textWrapper {
  @apply flex flex-col;
}

.countryNameText {
  @apply text-base leading-tighter text-gray-700;
}

.countryPathText {
  @apply text-sm leading-tight text-gray-600;
}

.content {
  @apply flex items-center;
}
