.blackout,
.drawer {
  @apply fixed top-0 z-modal h-full;
}

.themeDark .drawer {
  @apply bg-black text-white;
}
.themeLight .drawer {
  @apply bg-white text-black;
}

.blackout {
  @apply invisible left-0 w-full bg-black opacity-0;
  transition:
    opacity 75ms cubic-bezier(0, 0, 0.2, 1) 150ms,
    visibility 225ms;
}
.isOpen .blackout {
  @apply visible opacity-90;
  transition: opacity 200ms cubic-bezier(0, 0, 0.2, 1);
}
.noBlackout .blackout {
  @apply bg-transparent;
}

.drawer {
  @apply max-w-xl -translate-x-full;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  width: calc(100% - 58px);
}
.fromLeft .drawer {
  @apply left-0 -translate-x-full;
}
.fromRight .drawer {
  @apply right-0 translate-x-full;
}
.isOpen .drawer {
  @apply transform-none;
}

.scroll {
  @apply relative h-full w-full overflow-auto p-6;
}

.closeBtn {
  @apply invisible absolute top-0 m-1.5 p-3 text-white opacity-0;
  transition:
    opacity 75ms cubic-bezier(0, 0, 0.2, 1),
    visibility 75ms;
}
.isOpen .closeBtn {
  @apply visible opacity-100;
  transition: opacity 200ms cubic-bezier(0, 0, 0.2, 1) 150ms;
}
.fromLeft .closeBtn {
  @apply left-full;
}
.fromRight .closeBtn {
  @apply right-full;
}
