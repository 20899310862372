.button {
  @apply relative p-2;
}

.label {
  @apply hidden;
}

.count {
  @apply absolute top-0 left-0 flex h-5 w-5 items-center justify-center rounded-full bg-red-300 text-sm;
}

@screen lg {
  .button {
    @apply -mr-1.5 flex items-center space-x-2;
  }
  .label {
    @apply relative block font-medium text-gray-300;
  }
  .count {
    @apply relative -my-1 h-7 w-7 text-base;
  }
}
