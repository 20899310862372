.root {
  @apply max-h-0 w-full flex-shrink-0 overflow-hidden;
  transition: max-height 100ms ease-out 75ms;
}

.input {
  @apply relative mt-4 text-gray-700 opacity-0;
  transition: opacity 150ms linear;
  & input {
    @apply w-full appearance-none rounded-full border border-gray-400 py-4 px-10 text-sm leading-tight;
    &::placeholder {
      @apply text-gray-700;
    }
    &:focus {
      @apply outline-none;
    }
  }
}
.searchIcon {
  @apply pointer-events-none absolute top-1/2 left-4 h-4 w-4 -translate-y-1/2 opacity-50;
}

.autocomplete {
  @apply invisible fixed top-0 left-0 h-full w-full overflow-auto bg-white text-gray-700 opacity-0;
  transition:
    opacity 200ms ease 150ms,
    visibility 350ms linear;
}
.autocompleteCloseBtn {
  @apply fixed top-2 right-5.5 z-10 flex items-center justify-center rounded-full bg-black/20 p-px text-white;
  & svg {
    @apply m-2 h-7 w-7;
  }
}
.autocompleteResults {
  @apply pt-36 pb-16;
  &::after {
    @apply fixed top-0 left-0 h-32 w-full border-b bg-white;
    content: '';
  }
  & .head {
    @apply mb-3 text-xs font-medium uppercase tracking-wider text-gray-600;
  }
  & .hit,
  & .seeAll {
    @apply flex items-center space-x-3 border-b py-2.5 text-sm;
  }
  & .seeAll {
    @apply py-4 text-gray-400;
  }
  & .cover {
    @apply flex h-14 w-14 overflow-hidden rounded-md;
  }
  & .title {
    @apply mb-0.5 block line-clamp-2;
  }
  & .meta {
    @apply block text-xs text-gray-600 line-clamp-1;
  }
}

.root.showing {
  @apply max-h-20;
  transition: max-height 150ms ease-out;
  & .input {
    @apply opacity-100;
    transition: opacity 200ms linear 75ms;
  }
}
.root.autocompleteShowing {
  & .autocomplete {
    @apply visible opacity-100;
    transition:
      opacity 200ms ease,
      visibility 0s linear;
  }
}

@screen md {
  .root {
    @apply max-h-full flex-shrink px-4 transition-none;
  }
  .input {
    @apply my-0 opacity-100 transition-none;
  }
  .autocompleteCloseBtn {
    @apply fixed top-3 right-16 mt-px mr-1.5;
    & svg {
      @apply m-2.5;
    }
  }
  .autocompleteResults {
    @apply mx-px px-7 pt-28;
    &::after {
      @apply h-20;
    }
  }
}
@screen lg {
  .input input {
    @apply pl-12 text-base;
  }
  .autocompleteCloseBtn {
    @apply right-20 p-1;
    & svg {
      @apply m-2.5;
    }
  }
  .searchIcon {
    @apply left-5 h-5 w-5;
  }
}
@media screen and (min-width: 1485px) {
  .autocompleteCloseBtn {
    right: calc(50vw - 667px);
  }
}
