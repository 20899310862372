.imageContainer {
  @apply relative w-full overflow-hidden bg-gray-200/50;
  &.rounded,
  &.rounded img {
    @apply rounded-2xl;
  }
  &.transparentBg {
    @apply bg-transparent;
  }
}
.imageContainerInner {
  @apply relative max-w-full transition-opacity;
}
.loading .imageContainerInner {
  @apply opacity-0;
}
.contained {
  padding-bottom: 100%;
  & .imageContainerInner {
    @apply absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
  }
}
