.wrapper {
  @apply w-full text-center;
}

.title {
  @apply mb-8 mt-3.5 text-lg text-gray-700;
}

.buttonWrapper {
  @apply mt-3 flex justify-end;
}

.button {
  @apply mb-5 md:mb-0;
}
